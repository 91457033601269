.item {
  overflow: hidden;
  height: auto;
}

@media all {
  .item {
    max-height: 50em;
    -moz-transition: max-height 1s;
    -o-transition: max-height 1s;
    -webkit-transition: max-height 1s;
    transition: max-height 1s;
  }
}

@media screen and (min-width: 48em) {
  .item {
    max-height: 15em;
    -moz-transition: max-height 0.5s;
    -o-transition: max-height 0.5s;
    -webkit-transition: max-height 0.5s;
    transition: max-height 0.5s;
  }
}

.item.collapsed {
  max-height: 0;
}

.title {
  cursor: pointer;
  color: #fff;
  padding-left: 2.5rem;
  position: relative;

  &::before {
    font-weight: 400;
    content: "＋";
    left: 0px;
    margin-right: 25px;
    font-size: 28px;
    color: #00fffb;
    position: absolute;
  }

  &.open {
    &::before {
      font-weight: 400;
      content: "－";
      left: 0px;
      margin-right: 25px;
      font-size: 28px;
      color: #00fffb;
      position: absolute;
    }
  }
}

.title .emoji {
  margin-left: 2px;
  height: 1.5em;
  vertical-align: -0.4em;
}

.content {
  font-family: "Inter";
  padding-left: 2.5rem;
  white-space: pre-line;
  color: #bfc1c9;
}

.content a {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.content a:hover {
  text-decoration-color: rgba(181, 189, 219, 0.75);
}

.content .mono {
  font-family: "Roboto Mono";
}

.animateIn {
  -webkit-animation-name: accordionIn;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-direction: normal;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -webkit-animation-delay: 0s;
  -moz-animation-name: normal;
  -moz-animation-duration: 0.5s;
  -moz-animation-iteration-count: 1;
  -moz-animation-direction: alternate;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-fill-mode: both;
  -moz-animation-delay: 0s;
  animation-name: accordionIn;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-delay: 0s;
}

.animateOut {
  -webkit-animation-name: accordionOut;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  -webkit-animation-delay: 0s;
  -moz-animation-name: accordionOut;
  -moz-animation-duration: 0.5s;
  -moz-animation-iteration-count: 1;
  -moz-animation-direction: alternate;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-fill-mode: both;
  -moz-animation-delay: 0s;
  animation-name: accordionOut;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-delay: 0s;
}

@-webkit-keyframes accordionIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes accordionIn {
  0% {
    opacity: 0;
    -moz-transform: scale(0.8);
  }

  100% {
    opacity: 1;
    -moz-transform: scale(1);
  }
}

@keyframes accordionIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes accordionOut {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(0.8);
  }
}

@-moz-keyframes accordionOut {
  0% {
    opacity: 1;
    -moz-transform: scale(1);
  }

  100% {
    opacity: 0;
    -moz-transform: scale(0.8);
  }
}

@keyframes accordionOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.8);
    padding-bottom: 0;
  }
}

.wrapper a {
  color: #b5bddb;

  &:hover {
    text-decoration-line: underline;
  }
}
