/* scroller width */
.styled-scrollbar-vertical::-webkit-scrollbar {
  width: 5px;
}

.styled-scrollbar-horizontal::-webkit-scrollbar {
  height: 5px;
}

/* scroller track */
.styled-scrollbar::-webkit-scrollbar-track {
  background: rgb(27, 34, 54); /* tangaroa */
}

/* scroller handle */
.styled-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 3px;
  border: 3px solid white;
}

/* scroller handle on hover */
.styled-scrollbar::-webkit-scrollbar-thumb:hover {
  border-color: rgb(181, 189, 219); /* spindle */
}
